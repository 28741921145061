import React, { useState, useEffect } from "react";
import "./PrivacyPolicy.css";
import HHLogo from "../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import StaticHomePageLayout from "../../Layout/StaticHomePagelayout/StaticHomePagelayout";

const PrivacyPolicy = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
     }, []);

  // Simulate a loading delay with setTimeout
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the delay as needed
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <div className="hhloader-div">
            <img src={HHLogo} alt="hhlogo" className="logo-in-loader" />
            <Typewriter
              options={{
                strings: ["Loading..."],
                autoStart: true,
                loop: true,
                delay: 30,
              }}
              className="loading-text-in-loader"
            />
          </div>
        </>
      ) : (
        <>
          {" "}
          <StaticHomePageLayout>
            <header className="header-privacy-p">
              <div className="content-privacy-p">
                <div className="heading-privacy-p">Privacy Policy</div>
                <div className="date-privacy-p">Effective date: April 3, 2023</div>
              </div>
            </header>

            <section className="container-privacy-p">
              <div className="contain-privacy-p">
                <div className="left-privacy-p">
                  <div className="table-privacy-p">Table of contents</div>
                  <div className="table-heading-privacy-p">
                    <p className="table-h2-privacy-p">Heding 2</p>
                    <p className="table-h3-privacy-p">Heading 3</p>
                    <p className="table-h4-privacy-p">Heading 4</p>
                    <p className="table-h5-privacy-p">Heading 5</p>
                    <p className="table-h6-privacy-p">Heading 6</p>
                  </div>
                </div>

                <div className="right-privacy-p">
                  <div className="rich-text-privacy-p">
                    <div className="h2-privacy-p">
                      <h2 className="heading-2-privacy-p">Heading 2</h2>
                    </div>
                    <div className="p-h2-privacy-p">
                      <p className="h2-bold-privacy-p">
                        Dolor enim eu tortor urna sed duis nulla. Aliquam
                        vestibulum, nulla odio nisl vitae. In aliquet
                        pellentesque aenean hac vestibulum turpis mi bibendum
                        diam. Tempor integer aliquam in vitae malesuada
                        fringilla.
                      </p>
                    </div>
                    <div className="p-light-privacy-p">
                      <p className="h2-light-privacy-p">
                        Mi tincidunt elit, id quisque ligula ac diam, amet. Vel
                        etiam suspendisse morbi eleifend faucibus eget
                        vestibulum felis. Dictum quis montes, sit sit. Tellus
                        aliquam enim urna, etiam. Mauris posuere vulputate arcu
                        amet, vitae nisi, tellus tincidunt. At feugiat sapien
                        varius id.
                      </p>
                    </div>

                    <div className="h3-privacy-p">
                      <h3 className="heading-3-privacy-p">Heading 3</h3>
                    </div>
                    <div className="p-h3-privacy-p">
                      <p className="h3-bold-privacy-p">
                        Eget quis mi enim, leo lacinia pharetra, semper. Eget in
                        volutpat mollis at volutpat lectus velit, sed auctor.
                        Porttitor fames arcu quis fusce augue enim. Quis at
                        habitant diam at. Suscipit tristique risus, at donec. In
                        turpis vel et quam imperdiet. Ipsum molestie aliquet
                        sodales id est ac volutpat.
                      </p>
                    </div>
                    <div className="p-h3-light-privacy-p">
                      <p className="h3-light-privacy-p">
                        Tristique odio senectus nam posuere ornare leo metus,
                        ultricies. Blandit duis ultricies vulputate morbi
                        feugiat cras placerat elit. Aliquam tellus lorem sed ac.
                        Montes, sed mattis pellentesque suscipit accumsan.
                        Cursus viverra aenean magna risus elementum faucibus
                        molestie pellentesque. Arcu ultricies sed mauris
                        vestibulum.
                      </p>
                    </div>

                    <div className="h4-privacy-p">
                      <h4 className="heading-4-privacy-p">Heading 4</h4>
                    </div>
                    <div className="p-h4-privacy-p">
                      <p className="h4-bold-privacy-p">
                        Morbi sed imperdiet in ipsum, adipiscing elit dui
                        lectus. Tellus id scelerisque est ultricies ultricies.
                        Duis est sit sed leo nisl, blandit elit sagittis.
                        Quisque tristique consequat quam sed. Nisl at
                        scelerisque amet nulla purus habitasse.
                      </p>
                    </div>

                    <div className="h5-privacy-p">
                      <h5 className="heading-5-privacy-p">Heading 5</h5>
                    </div>
                    <div className="p-h5-privacy-p">
                      <p className="h5-bold-privacy-p">
                        Morbi sed imperdiet in ipsum, adipiscing elit dui
                        lectus. Tellus id scelerisque est ultricies ultricies.
                        Duis est sit sed leo nisl, blandit elit sagittis.
                        Quisque tristique consequat quam sed. Nisl at
                        scelerisque amet nulla purus habitasse.
                      </p>
                    </div>
                    <div className="p-h5-light-privacy-p">
                      <div className="divider-privacy-p">
                        <p className="divide-privacy-p"></p>
                        <p className="h5-light-privacy-p">
                          "Ipsum sit mattis nulla quam nulla. Gravida id gravida
                          ac enim mauris id. Non pellentesque congue eget
                          consectetur turpis. Sapien, dictum molestie sem
                          tempor. Diam elit, orci, tincidunt aenean tempus."
                        </p>
                      </div>
                    </div>

                    <div className="h6-privacy-p">
                      <h6 className="heading-6-privacy-p">Heading 6</h6>
                    </div>
                    <div className="p-h6-privacy-p">
                      <p className="h6-bold-privacy-p">
                        Nunc sed faucibus bibendum feugiat sed interdum. Ipsum
                        egestas condimentum mi massa. In tincidunt pharetra
                        consectetur sed duis facilisis metus. Etiam egestas in
                        nec sed et. Quis lobortis at sit dictum eget nibh tortor
                        commodo cursus.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </StaticHomePageLayout>
        </>
      )}
    </>
  );
};

export default PrivacyPolicy;
