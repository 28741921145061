import React from "react";
import "./AboutUsTeamCard.css";
import aboutcard from "../../Assets/Images/aboutcard.png";
import { BiLogoTwitter } from "react-icons/bi";
import { AiFillLinkedin } from "react-icons/ai";
import { CiBasketball } from "react-icons/ci";

const AboutUsTeamCard = () => {
  return (
    <div>
      <div>
        
          
          
          <img src={aboutcard} className="aboutus-card-img" />
       
        <div className="transper-card-about">
          <p className="card-per-name">Alisa Hester</p>
          <p className="card-per-postion">Founder & CEO</p>
          <p className="card-per-postion">
            Lorem ipsum dolor sit amet consectetur. Vitae mattis venenatis
            nullam tempor nisi blandit.
          </p>
          <p className="card-per-icon">
            <BiLogoTwitter />
            <AiFillLinkedin />
            <CiBasketball />
          </p>
        </div>
      </div>
    </div>
  );
};
export default AboutUsTeamCard;
