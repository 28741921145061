import React from "react";
import i18n from "../../i18n";
import engFlag from "../../Assets/Images/englishflag.png";
import thaiflag from "../../Assets/Images/thailand.png";
import logo from "../../Assets/Svg/logo.svg";
import { VscThreeBars } from "react-icons/vsc";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./TopBarStaticPage.css";

const TopBarStaticPage = ({selectedLanguage, changeLanguage}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // loading
  const { t } = useTranslation(); //for languge conversion

  // const changeLanguage = (lng) => {
  //   // console.log(`Changing language1 to ${lng}`);
  //   i18n.changeLanguage(lng);
  //   // setSelectedLanguage(lng);
  //   // console.log(`Changing language to ${lng}`);
  // };
  const handleClickSignup = () => {
    navigate("/register");
  };

  const handleJobClick = () => {
    const latestJobsSection = document.getElementById("latest-jobs");

    if (latestJobsSection) {
      latestJobsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleServicesClick = () => {
    const servicesSection = document.getElementById("services");

    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="static-top-bar-head">
      <Link to="/">
        <img src={logo} alt="logo" className="logo" />
      </Link>
      <div className="navigation">
        <nav>
          <ul>
            <li>
              <a href="/" className="">
                {t("translation.home")}
              </a>
            </li>
            <li>
              <a href="/about-us" className="">
                {t("translation.aboutus")}
              </a>
            </li>
            <li>
              <a href="/#latest-jobs" onClick={handleJobClick}>
                {t("translation.navjob")}
              </a>
            </li>
            <li>
              <a href="/#services" onClick={handleServicesClick}>
                {t("translation.Services")}
              </a>
            </li>
            <li>
              <a href="/contact-us" className="">
                {t("translation.ContactUs")}
              </a>
            </li>
          </ul>
        </nav>

        <button className="blue-button" onClick={handleClickSignup}>
          Sign Up <BsArrowRight />
        </button>
        <div className="dropdown-head">
          <div className="custom-select">
          {i18n.language === "en" ? (
              <img src={engFlag} alt="English Flag" className="flag-icon" />
            ) : (
              <img src={thaiflag} alt="Thai Flag" className="flag-icon" />
            )}
            
            <select
              className="selectBox selectBox-langu-change"
              name="lang"
              onChange={(e) => changeLanguage(e.target.value)}
              value={selectedLanguage}
            >
              <option value="en" className="lan-change-option-drpdown">
                English
              </option>
              <option value="thai" className="lan-change-option-drpdown">
                Thai
              </option>
            </select>
            <span className="custom-arrow lang-change-arrow"></span>
          </div>

          
        </div>
      </div>
      <div className="static-tog-btn">
        <VscThreeBars />
      </div>
    </div>
  );
};
export default TopBarStaticPage;
