import React, { useState, useEffect } from "react";
import "./AboutUs.css";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.min.css";
import "swiper/css/navigation";
import Aboutbanner from "../../Assets/Images/Aboutbanner.png";
import mobileabout from "../../Assets/Images/mobileabout.png";
import aboutsec1 from "../../Assets/Images/aboutsec1.png";
import aboutsec2 from "../../Assets/Images/aboutsec2.png";
import AboutUsTeamCard from "../../Components/AboutUsTeamCard/AboutUsTeamCard";
import TestimonalCard from "../../Components/TestimonalCard/TestimonalCard";
import { AiOutlineArrowRight } from "react-icons/ai";
import StaticHomePageLayout from "../../Layout/StaticHomePagelayout/StaticHomePagelayout";
import HHLogo from "../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/swiper.min.css";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  // const [isLoading, setIsLoading] = useState(true);

  // Simulate a loading delay with setTimeout
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000); // Adjust the delay as needed
  // }, []);
  const { t, i18n } = useTranslation(); //for languge conversion

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <StaticHomePageLayout>
        <div>
          <div className="full-banner-about-sec">
            <div className="banner-area-about-sec">
              <img src={Aboutbanner} alt="" className="picture-about-sec" />
              <img
                src={mobileabout}
                alt=""
                className="picture-mobile-about-sect"
              />

              <div className="content-head-sec-about">
                <h1 className="about-sec-headi">
                  {t("aboutUsTranslations.ConnectingHearts")},<br></br>
                  {t("aboutUsTranslations.EmpoweringHomes")}

                </h1>
                
                <p className="about-sec-parag">
                  {t("aboutUsTranslations.aboutusTag")}
                </p>
              </div>
            </div>
          </div>

          {/* <!-- Home section End --> */}

          {/* <!-- layout start from here --> */}
          <div className="about-layout">
            <div className="column-about-sec">
              <p className="column-about-sec-tag">
                {" "}
                {t("aboutUsTranslations.Ourstory")}
              </p>
              <h2 className="column-about-sec-heading">
                {t("aboutUsTranslations.OurStoryTag")}
              </h2>
            </div>

            <div className="Timeline">
              <div className="Intern">
                <div className="icons">
                  <div className="component">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M41.46 14.24L41.18 13.74C40.8188 13.1354 40.3094 12.6329 39.7 12.28L26.28 4.54C25.6724 4.1875 24.9826 4.00124 24.28 4H23.7C22.9974 4.00124 22.3076 4.1875 21.7 4.54L8.28 12.3C7.67394 12.6505 7.17052 13.1539 6.82 13.76L6.54 14.26C6.1875 14.8677 6.00124 15.5575 6 16.26V31.76C6.00124 32.4626 6.1875 33.1524 6.54 33.76L6.82 34.26C7.17958 34.859 7.68098 35.3604 8.28 35.72L21.72 43.46C22.3246 43.8198 23.0164 44.0066 23.72 44H24.28C24.9826 43.9988 25.6724 43.8126 26.28 43.46L39.7 35.7C40.312 35.3574 40.8174 34.852 41.16 34.24L41.46 33.74C41.8082 33.1306 41.9942 32.442 42 31.74V16.24C41.9988 15.5375 41.8126 14.8477 41.46 14.24ZM23.7 8H24.28L36 14.76L24 21.68L12 14.76L23.7 8ZM26 39L37.7 32.24L38 31.74V18.22L26 25.16V39Z"
                        fill="#04AFF0"
                      />
                    </svg>
                  </div>
                  <div className="divider">
                    <svg
                      width="2"
                      height="100"
                      viewBox="0 0 2 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="1"
                        y1="4.37114e-08"
                        x2="0.999996"
                        y2="100"
                        stroke="#04AFF0"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                </div>
                <div className="text">
                  <h6>Year • Month</h6>
                  <h4> {t("aboutUsTranslations.Summaryofevent")}</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique.
                  </p>
                </div>
              </div>
              <div className="Intern">
                <div className="icons">
                  <div className="component">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M41.46 14.24L41.18 13.74C40.8188 13.1354 40.3094 12.6329 39.7 12.28L26.28 4.54C25.6724 4.1875 24.9826 4.00124 24.28 4H23.7C22.9974 4.00124 22.3076 4.1875 21.7 4.54L8.28 12.3C7.67394 12.6505 7.17052 13.1539 6.82 13.76L6.54 14.26C6.1875 14.8677 6.00124 15.5575 6 16.26V31.76C6.00124 32.4626 6.1875 33.1524 6.54 33.76L6.82 34.26C7.17958 34.859 7.68098 35.3604 8.28 35.72L21.72 43.46C22.3246 43.8198 23.0164 44.0066 23.72 44H24.28C24.9826 43.9988 25.6724 43.8126 26.28 43.46L39.7 35.7C40.312 35.3574 40.8174 34.852 41.16 34.24L41.46 33.74C41.8082 33.1306 41.9942 32.442 42 31.74V16.24C41.9988 15.5375 41.8126 14.8477 41.46 14.24ZM23.7 8H24.28L36 14.76L24 21.68L12 14.76L23.7 8ZM26 39L37.7 32.24L38 31.74V18.22L26 25.16V39Z"
                        fill="#04AFF0"
                      />
                    </svg>
                  </div>
                  <div className="divider">
                    <svg
                      width="2"
                      height="100"
                      viewBox="0 0 2 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="1"
                        y1="4.37114e-08"
                        x2="0.999996"
                        y2="100"
                        stroke="#04AFF0"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                </div>
                <div className="text">
                  <h6>Year • Month</h6>
                  <h4> {t("aboutUsTranslations.Summaryofevent")}</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique.
                  </p>
                </div>
              </div>
              <div className="Intern">
                <div className="icons">
                  <div className="component">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M41.46 14.24L41.18 13.74C40.8188 13.1354 40.3094 12.6329 39.7 12.28L26.28 4.54C25.6724 4.1875 24.9826 4.00124 24.28 4H23.7C22.9974 4.00124 22.3076 4.1875 21.7 4.54L8.28 12.3C7.67394 12.6505 7.17052 13.1539 6.82 13.76L6.54 14.26C6.1875 14.8677 6.00124 15.5575 6 16.26V31.76C6.00124 32.4626 6.1875 33.1524 6.54 33.76L6.82 34.26C7.17958 34.859 7.68098 35.3604 8.28 35.72L21.72 43.46C22.3246 43.8198 23.0164 44.0066 23.72 44H24.28C24.9826 43.9988 25.6724 43.8126 26.28 43.46L39.7 35.7C40.312 35.3574 40.8174 34.852 41.16 34.24L41.46 33.74C41.8082 33.1306 41.9942 32.442 42 31.74V16.24C41.9988 15.5375 41.8126 14.8477 41.46 14.24ZM23.7 8H24.28L36 14.76L24 21.68L12 14.76L23.7 8ZM26 39L37.7 32.24L38 31.74V18.22L26 25.16V39Z"
                        fill="#04AFF0"
                      />
                    </svg>
                  </div>
                  <div className="divider">
                    <svg
                      width="2"
                      height="100"
                      viewBox="0 0 2 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="1"
                        y1="4.37114e-08"
                        x2="0.999996"
                        y2="100"
                        stroke="#04AFF0"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                </div>
                <div className="text">
                  <h6>Year • Month</h6>
                  <h4> {t("aboutUsTranslations.Summaryofevent")}</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique.
                  </p>
                </div>
              </div>
              <div className="Intern">
                <div className="icons">
                  <div className="component">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M41.46 14.24L41.18 13.74C40.8188 13.1354 40.3094 12.6329 39.7 12.28L26.28 4.54C25.6724 4.1875 24.9826 4.00124 24.28 4H23.7C22.9974 4.00124 22.3076 4.1875 21.7 4.54L8.28 12.3C7.67394 12.6505 7.17052 13.1539 6.82 13.76L6.54 14.26C6.1875 14.8677 6.00124 15.5575 6 16.26V31.76C6.00124 32.4626 6.1875 33.1524 6.54 33.76L6.82 34.26C7.17958 34.859 7.68098 35.3604 8.28 35.72L21.72 43.46C22.3246 43.8198 23.0164 44.0066 23.72 44H24.28C24.9826 43.9988 25.6724 43.8126 26.28 43.46L39.7 35.7C40.312 35.3574 40.8174 34.852 41.16 34.24L41.46 33.74C41.8082 33.1306 41.9942 32.442 42 31.74V16.24C41.9988 15.5375 41.8126 14.8477 41.46 14.24ZM23.7 8H24.28L36 14.76L24 21.68L12 14.76L23.7 8ZM26 39L37.7 32.24L38 31.74V18.22L26 25.16V39Z"
                        fill="#04AFF0"
                      />
                    </svg>
                  </div>
                  <div className="divider">
                    <svg
                      width="2"
                      height="100"
                      viewBox="0 0 2 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="1"
                        y1="4.37114e-08"
                        x2="0.999996"
                        y2="100"
                        stroke="#04AFF0"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                </div>
                <div className="text">
                  <h6>Year • Month</h6>
                  <h4> {t("aboutUsTranslations.Summaryofevent")}</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique.
                  </p>
                </div>
              </div>
              <div className="Intern">
                <div className="icons">
                  <div className="component">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M41.46 14.24L41.18 13.74C40.8188 13.1354 40.3094 12.6329 39.7 12.28L26.28 4.54C25.6724 4.1875 24.9826 4.00124 24.28 4H23.7C22.9974 4.00124 22.3076 4.1875 21.7 4.54L8.28 12.3C7.67394 12.6505 7.17052 13.1539 6.82 13.76L6.54 14.26C6.1875 14.8677 6.00124 15.5575 6 16.26V31.76C6.00124 32.4626 6.1875 33.1524 6.54 33.76L6.82 34.26C7.17958 34.859 7.68098 35.3604 8.28 35.72L21.72 43.46C22.3246 43.8198 23.0164 44.0066 23.72 44H24.28C24.9826 43.9988 25.6724 43.8126 26.28 43.46L39.7 35.7C40.312 35.3574 40.8174 34.852 41.16 34.24L41.46 33.74C41.8082 33.1306 41.9942 32.442 42 31.74V16.24C41.9988 15.5375 41.8126 14.8477 41.46 14.24ZM23.7 8H24.28L36 14.76L24 21.68L12 14.76L23.7 8ZM26 39L37.7 32.24L38 31.74V18.22L26 25.16V39Z"
                        fill="#04AFF0"
                      />
                    </svg>
                  </div>
                  {/* <div className="divider">
              <svg
                width="2"
                height="100"
                viewBox="0 0 2 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="1"
                  y1="4.37114e-08"
                  x2="0.999996"
                  y2="100"
                  stroke="#04AFF0"
                  stroke-width="2"
                />
              </svg>
            </div> */}
                </div>
                <div className="text">
                  <h6>Year • Month</h6>
                  <h4> {t("aboutUsTranslations.Summaryofevent")}</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Layout end here --> */}
          {/* <!-- Start our vision from here --> */}
          <div className="about-vision">
            <div className="about-frame">
              <p className="about-frame-tag">
                {" "}
                {t("aboutUsTranslations.Ourvision")}
              </p>
              <div className="abo-text">
                <div className="left">
                  Lorem ipsum dolor sit amet consectetur. Velit in nunc
                  hendrerit quam tellus interdum pellentesque. Dui adipiscing
                  semper orci in. Iaculis pharetra libero quam faucibus purus
                  massa at ut. Sit et viverra nulla aliquet et adipiscing. Sed
                  feugiat ac pulvinar bibendum sit viverra adipiscing elit.
                  Felis aenean et.
                </div>
                <div className="right">
                {t("aboutUsTranslations.OurvisionPara")}
                </div>
              </div>
            </div>
          </div>

          {/* <!-- End our vision  here --> */}

          {/* <!-- start our values from here  --> */}

          <div className="ab-values">
            <div className="ab-section">
              <p className="about-section-our-values-tag">{t("aboutUsTranslations.Ourvalues")}</p>
              <div className="ab-content">
                <h2 className="about-section-our-values-heading">
                {t("aboutUsTranslations.OurvaluesHeading")}
                </h2>
                <h6 className="about-section-our-values-para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse varius enim in eros elementum tristique. Duis
                  cursus, mi quis viverra ornare.
                </h6>
              </div>
            </div>
            <div className="ab-row">
              <div className="ab-column">
                <img src={aboutsec1} className="img1" />
                <div className="ab-para">
                  <h4>Highlight value one</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </p>
                </div>
              </div>
              <div className="ab-column">
                <img src={aboutsec2} className="img2" />
                <div className="ab-para">
                  <h4>Highlight value two</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </p>
                </div>
              </div>
              <div className="ab-column">
                <img src={aboutsec1} className="img3" />
                <div className="ab-para">
                  <h4>Highlight value three</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End our values here  --> */}

          {/* <!-- Team start here --> */}

          <div className="ab-us-team">
            <div className="ab-us-content">
              <h2 className="ab-us-content-heading">{t("aboutUsTranslations.TeamsThatCare")}</h2>
              <p className="ab-us-content-tagline">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
              </p>
            </div>
            <div className="ab-us-members">
              <Swiper
                loop={true} // Add this line to enable continuous looping
                slidesPerView={3}
                spaceBetween={100}
                autoplay={{ delay: 700 }}
                pagination={{ clickable: true }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide className="swiper-slide">
                  <AboutUsTeamCard />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <AboutUsTeamCard />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <AboutUsTeamCard />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <AboutUsTeamCard />
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <AboutUsTeamCard />
                </SwiperSlide>
              </Swiper>
              {/* <div className="upper2">
                    <AboutUsTeamCard />
                  </div>
                  <div className="upper3">
                    <AboutUsTeamCard />
                  </div>
                  <div className="upper4">
                    <AboutUsTeamCard />
                  </div> */}
            </div>
            {/* <div className="slider-btn-home">
            <div className="arrow-btn-home">
              <AiOutlineArrowRight />
            </div>
            <div className="arrow-btn-home">
              <AiOutlineArrowRight />
            </div>
          </div> */}
          </div>
          <div className="testimonial">
            <div className="t-head">
              <h2 className="hh-home-cu-testi-heading">
              {t("aboutUsTranslations.Customertestimonials")}
              </h2>
              <p className="hh-home-cu-testi-tagline">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
            <div className="boxes-with-dots">
              <div className="boxes">
                {/* <!-- Box of One Customer --> */}
                <Swiper
                  loop={true} // Add this line to enable continuous looping
                  slidesPerView={2}
                  spaceBetween={30}
                  autoplay={{ delay: 700 }}
                  pagination={{ clickable: true }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper"
                >
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard />
                  </SwiperSlide>
                </Swiper>
                {/* <TestimonalCard /> */}
                {/* <TestimonalCard /> */}
                {/* <TestimonalCard /> */}
              </div>{" "}
            </div>
          </div>

          {/* <!-- Team end here --> */}
        </div>
      </StaticHomePageLayout>
    </>
  );
};

export default AboutUs;
