import React from "react";
import { ImLocation } from "react-icons/im";
import { MdOutlineWatchLater } from "react-icons/md";
import { useNavigate } from "react-router-dom";


const JobTitleCard = ({
  title,
  department,
  expectations,
  location,
  contractType,
}) => {
  const navigate = useNavigate();


  const handleClickSignup = () => {
    navigate("/login");
  };

  return (
   
        <div className="card">
          <div className="head-home-pg">
            <div className="title-job-home">
              <h5>{title}</h5>
              <label className="Dep">Tag</label>
            </div>
            <p>{expectations}</p>
          </div>
          <div className="info-home">
            <div className="info2-home">
              <ImLocation className="infi-ic" />
              <p>{location}</p>
            </div>
            
          </div>
          <button className="blue-button"  onClick={handleClickSignup}>Apply Now</button>
        </div>
   
  );
};

export default JobTitleCard;
