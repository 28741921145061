import React from "react";
import person4 from "../../Assets/Images/person/person4.png";
import { AiFillStar } from "react-icons/ai";

const TestimonalCard =() =>
{
    return(
        <div className="box">
          <div className="star">
             <AiFillStar className="testimo-star" />
             <AiFillStar className="testimo-star" />
             <AiFillStar className="testimo-star" />
             <AiFillStar className="testimo-star" />
             <AiFillStar className="testimo-star" />
            </div>
            <div className="box-content">
              <p className="hh-home-cu-testi-review">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementu cursus, mi quis viverra ornare."</p>
              <div className="box-c-1">
                <img src={person4} alt="person" className="hh-home-cu-testi-img"  />
                <div className="box-c-2">
                  <h6 className="hh-home-cu-testi-name"> Name Surname</h6>
                  <p className="hh-home-cu-testi-details">Position, Company Name</p>
                </div>
              </div>
            </div>
          </div>
    )
}
export default TestimonalCard;