import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import "./ContactUs.css";
import TopBarStaticPage from "../../Components/TopBarStaticPage/TopBarStaticPage";
import StaticPageFooter from "../../Components/StaticPageFooter/StaticPageFooter";
import Bannercontact from "../../Assets/Images/Bannercontact.png";
import Map from "../../Assets/Images/Map.png";
import mobilemap from "../../Assets/Images/mobilemap.png";
import { IoMailSharp } from "react-icons/io5";
import { IoIosCall } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import { POST_CONTACT_US } from "../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import StaticHomePageLayout from "../../Layout/StaticHomePagelayout/StaticHomePagelayout";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t, i18n } = useTranslation(); //for languge conversion
  const [mapSrc, setMapSrc] = useState(
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3152.0739171582407!2d-122.41964658456983!3d37.7749298797601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808580d8c51d27cb%3A0xe29b13e3e22ce215!2sSan%20Francisco%2C%20CA!5e0!3m2!1sen!2sus!4v1634069131239!5m2!1sen!2sus"
  );
  const handleViewMap = (newMapSrc) => {
    setMapSrc(newMapSrc);
  };
  const URL_LOCATION_ADDRESS =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3952.3805319972817!2d98.34454067668186!3d7.855183636084964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30502fca64dfd7fb%3A0x987a79c5702a9b55!2sChalong%2C%20Mueang%20Phuket%20District%2C%20Phuket%2083130%2C%20Thailand!5e0!3m2!1sen!2sin!4v1697104700426!5m2!1sen!2sin";
  const THAILAND_LOCATION_URL =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d496115.241727904!2d100.00911482197561!3d13.724483990777978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d6032280d61f3%3A0x10100b25de24820!2sBangkok%2C%20Thailand!5e0!3m2!1sen!2sin!4v1697105104694!5m2!1sen!2sin";
  const INDIA_LOCATION_URL =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241316.672927324!2d72.71637245958217!3d19.082502005305862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c6306644edc1%3A0x5da4ed8f8d648c69!2sMumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1697105301945!5m2!1sen!2sin";
  const USA_LOCATION_URL =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.03608453594!2d-74.30933162340544!3d40.69753996075535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1697105340217!5m2!1sen!2sin";
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const Token = localStorage.getItem("token");

  const onSubmit = (data) => {
    // console.log(data);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var raw = JSON.stringify({
      firstName: data.Firstname_c,
      lastName: data.Lastname_c,
      phoneNumber: data.phoneno_c,
      email: data.email_c,
      message: data.message_c,
      // phoneNumber: data.password,
    });
    // console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${POST_CONTACT_US}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "contact data");
        if (result.statuscode === 201) {
          toast.info("Contact Us Data Save Successfully");
          window.location.reload();
          window.scrollTo(0, 0);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <StaticHomePageLayout>
        <div>
          <div className="contact-new-container">
            <div>
              {/* <!-- Home section start --> */}

              <div className="banner-area-contact">
                {/* <img
                  src={Bannercontact}
                  alt=""
                  className="picture-home-cotact"
                /> */}
                {/* <img src={mobilebanner} alt="" className="picture-mobile-home-b" /> */}
                <div className="content-contact-head">
                  <h1 className="first-head-conct">
                    {t("contactUsTranslations.GetinTouch")} :{" "}
                    {t("contactUsTranslations.ContactUsToday")}!
                  </h1>
                  <p className="second-head-conct">
                    {t("contactUsTranslations.ContactUsTag")}
                  </p>
                </div>
              </div>
              {/* <!-- Home section end --> */}
            </div>

            {/* <!-- contact module start --> */}
            <div className="contact">
              <form
                className="contact-form-us"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="title">
                  <h2>{t("contactUsTranslations.Contactus")}</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
                  </p>
                </div>
                <div className="form">
                  <div className="row">
                    <div className="first">
                      <label>First name</label>
                      <input
                        className="emp-form-input"
                        type="text"
                        {...register("Firstname_c", {
                          required: true,
                        })}
                      />
                      {errors.Firstname_c && (
                        <p className="error-msg-c">
                          Please Enter The FirstName
                        </p>
                      )}
                    </div>
                    <div className="first">
                      <label>Last name</label>
                      <input
                        className="emp-form-input"
                        type="text"
                        {...register("Lastname_c", {
                          required: true,
                        })}
                      />
                      {errors.Lastname_c && (
                        <p className="error-msg-c">Please Enter The LastName</p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="first">
                      <label>Email</label>
                      <input
                        className="emp-form-input"
                        type="email"
                        {...register("email_c", {
                          required: true,
                        })}
                      />
                      {errors.email_c && (
                        <p className="error-msg-c">Please Enter The Email</p>
                      )}
                    </div>
                    <div className="first">
                      <label>Phone number</label>
                      <input
                        className="emp-form-input"
                        type="number"
                        {...register("phoneno_c", {
                          required: true,
                          maxLength: 10,
                        })}
                      />
                      {errors.phoneno_c && (
                        <p className="error-msg-c">
                          Please Enter 10 digit valid Phone no
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="radios">
                    <label>Which best describes you?</label>
                    <div className="content">
                      <div className="col-cotact-us1">
                        <div className="radio">
                          <input type="radio" name="Employee" id="" />
                          Employee
                        </div>
                        <div className="radio">
                          <input type="radio" name="Other" id="" />
                          Other
                        </div>
                      </div>
                      <div className="col-cotact-us1">
                        <div className="radio">
                          <input type="radio" name="Customer" id="" />
                          Customer
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="msg">
                    <p>Message</p>
                    <textarea
                      name="message_c"
                      id=""
                      col-cotact-uss="30"
                      rows="10"
                      placeholder="Type your message..."
                      {...register("message_c", {
                        required: true,
                      })}
                    />
                    {errors.message_c && (
                      <p className="error-msg-c">
                        Please Enter The description
                      </p>
                    )}
                  </div>
                  <div className="checkbox">
                    <input type="checkbox" name="" id="" />{" "}
                    <label>I accept the Terms</label>
                  </div>
                  <button className="contact-us-button" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/* <!-- contact module end --> */}

            {/* <!-- Row start here --> */}
            <div className="info">
              <div className="email-contact-se">
                <div className="frame-conact-us">
                  <IoMailSharp className="contact-ic" />
                </div>
                <div className="para-conta">
                  <div className="col-cotact-us">
                    <h4> {t("contactUsTranslations.email")}</h4>
                    <p className="cont-text-form">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in ero.
                    </p>
                  </div>
                  <a href="/">hello@relume.io</a>
                </div>
              </div>
              <div className="email-contact-se">
                <div className="frame-conact-us">
                  <IoIosCall className="contact-ic" />
                </div>
                <div className="para-conta">
                  <div className="col-cotact-us">
                    <h4> {t("contactUsTranslations.Phone")}</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in ero.
                    </p>
                  </div>
                  <a href="/">+1 (555) 000-0000</a>
                </div>
              </div>
              <div className="email-contact-se">
                <div className="frame-conact-us">
                  <FaLocationDot className="contact-ic" />
                </div>
                <div className="para-conta">
                  <div className="col-cotact-us">
                    <h4> {t("contactUsTranslations.Office")}</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in ero.
                    </p>
                  </div>
                  <a href="/">123 Sample St, Sydney NSW 2000 AU</a>
                </div>
              </div>
            </div>
            {/* <!-- Row end here --> */}

            {/* <!-- location start from here --> */}
            <div className="location">
              <div className="sec1">
                <p>{t("contactUsTranslations.Ouroffices")}</p>
                <h2>{t("contactUsTranslations.Locations")}</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              </div>
              <div className="sec2">
                <div className="tab">
                  <div className="horizontal">
                    <div className="con">
                      <h3 className="contact-loc-p country-location-name">
                        Bangkok
                      </h3>
                      <p>123 Sample St, Bangkok, Thailand</p>
                      <button
                        className="contact-a-tag"
                        onClick={() => handleViewMap(THAILAND_LOCATION_URL)}
                      >
                        {t("contactUsTranslations.ViewMap")}
                      </button>
                    </div>
                  </div>
                  <div className="horizontal">
                    <div className="con">
                      <h3 className="contact-loc-p country-location-name">
                        New York
                      </h3>
                      <p>123 Sample St, New York 10000 USA</p>
                      <button
                        className="contact-a-tag"
                        onClick={() => handleViewMap(USA_LOCATION_URL)}
                      >
                        {t("contactUsTranslations.ViewMap")}
                      </button>
                    </div>
                  </div>
                  <div className="horizontal">
                    <div className="con">
                      <h3 className="contact-loc-p country-location-name">
                        India
                      </h3>
                      <p>123 Sample St, Mumabai, India</p>

                      <button
                        className="contact-a-tag"
                        onClick={() => handleViewMap(INDIA_LOCATION_URL)}
                      >
                        {t("contactUsTranslations.ViewMap")}
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="placehold">
                  <img src={Map} className="desktop-map-img" alt="" srcset="" />
                  <img
                    src={mobilemap}
                    className="mobile-map-contact"
                    alt=""
                    srcset=""
                  />
                </div> */}
                <div className="contact-us-page-map-div">
                  <iframe
                    title="Interactive Map"
                    width="100%"
                    height="440"
                    frameBorder="0"
                    src={mapSrc ?? URL_LOCATION_ADDRESS}
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            {/* <!-- location end from here --> */}
          </div>
        </div>
      </StaticHomePageLayout>
    </>
  );
};
export default ContactUs;
