import React, { useEffect, useState } from "react";
import "./HomePage.css";
import { useTranslation } from "react-i18next";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.min.css";
import "swiper/css/navigation";
import { useRef } from "react";
import sectionbanner from "../../Assets/Images/sectionbanner.png";
import usercomment from "../../Assets/Svg/usercomment.svg";
import mobilebanner from "../../Assets/Images/mobilebanner.png";
import serviceImage from "../../Assets/Images/icons/serviceImage.png";
import serviceImage2 from "../../Assets/Images/icons/serviceImage2.png";
import serviceImage3 from "../../Assets/Images/icons/serviceImage3.png";
import latestjobwomen from "../../Assets/Images/latestjobwomen.png";
import Handshake from "../../Assets/Images/icons//Handshake.png";
import Onlineshop from "../../Assets/Images/icons/Onlineshop.png";
import Support from "../../Assets/Images/icons/Support.png";
import wood from "../../Assets/Images/wood.png";
import mobilewood from "../../Assets/Images/mobilewood.png";
import StaticHomePageLayout from "../../Layout/StaticHomePagelayout/StaticHomePagelayout";
import TestimonalCard from "../../Components/TestimonalCard/TestimonalCard";
import LatestJoiningCard from "../../Components/LatestJoiningCard/LatestJoiningCard";
import parse from "html-react-parser";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import {
  GET_LATEST_JOINING,
  GET_LATEST_JOB,
} from "../../ApiEndPoints/ApiEndPoints";
import JobTitleCard from "../../Components/JobTitleCard/JobTitleCard";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/navigation";
import "swiper/swiper.min.css";
import HHLogo from "../../Assets/Images/logo.png";
import Typewriter from "typewriter-effect";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import NoJobsFoundImage from "../../Assets/Images/nojobsfound.png";

const HomePage = () => {
  const [latestJoinee, setLatestJoinee] = useState([]);
  const [jobs, setJobs] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const swiperRef = useRef(null);
  const { t } = useTranslation();

  const Token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${GET_LATEST_JOINING}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("LATEST-JOINIEE", result);
        // setIsLoading(false);
        setLatestJoinee(result.employees);
      })
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${GET_LATEST_JOB}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("LATEST-JOB", data);
        // setIsLoading(false);
        setJobs(data.latestJob);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const slides = [
    {
      image: sectionbanner,
      text: t("translation.homeslide1text"),
      description: t("translation.homeslide1description"),
    },
  ];

  const nextSlide = () => {
    console.log("Next slide button clicked");
    swiperRef.current.swiper.slideNext();
  };

  // Function to handle previous slide
  const prevSlide = () => {
    swiperRef.current.swiper.slidePrev();
  };

  return (
    <>
      <StaticHomePageLayout>
        <div className="home-main-pg-container">
          <Swiper
            ref={swiperRef}
            loop={true}
            autoplay={{ delay: 4000 }}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination, Navigation]}
            // onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
          >
            {slides.map((slide, index) => (
              <SwiperSlide className="banner-swiper-image" key={index}>
                <div className="full-banner">
                  <div className="banner-area-home">
                    <img src={slide.image} alt="" className="picture-home-b" />
                    <img
                      src={mobilebanner}
                      alt=""
                      className="picture-mobile-home-b"
                    />

                    <div className="content-head-section-st">
                      <h1 className="b-1">{slide.text}</h1>
                      <p className="b-2">{slide.description}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="below-banner">
            {/* Custom arrow buttons */}
            <div className="slider-btn">
              <div className="arrow-btn custom-prev-btn" onClick={prevSlide}>
                <AiOutlineArrowLeft />
              </div>
              <div className="arrow-btn custom-next-btn" onClick={nextSlide}>
                <AiOutlineArrowRight />
              </div>
            </div>
          </div>

          {/* <!-- Services Section --> */}

          <div
            id="services"
            className="services-main background-gradient-color"
          >
            <div className="services-head">
              <h2>{t("translation.ourservices")}</h2>
              <p>{t("translation.ourservicesTag")}</p>
            </div>
            <div className="service-frame">
              <div className="frame-ho">
                <div className="frame1-1">
                  <div className="f1-2">
                    <div className="f1-22">
                      <img src={serviceImage} alt="" className="" />
                      <h4 className="hh-home-our-ser-tag">
                        {t("translation.Maids")}
                      </h4>
                    </div>
                    <div className="hh-home-our-ser-para-div">
                      <p className="hh-home-our-ser-para">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse varius enim
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-ho">
                <div className="frame1-1">
                  <div className="f1-2">
                    <div className="f1-22">
                      <img src={serviceImage2} alt="" className="" />
                      <h4 className="hh-home-our-ser-tag">
                        {t("translation.Nanny")}
                      </h4>
                    </div>
                    <div className="hh-home-our-ser-para-div">
                      <p className="hh-home-our-ser-para">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse varius enim
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-ho">
                <div className="frame1-1">
                  <div className="f1-2">
                    <div className="f1-22">
                      <img src={serviceImage3} alt="" className="" />
                      <h4 className="hh-home-our-ser-tag">
                        {t("translation.Caregiver")}
                      </h4>
                    </div>
                    <div className="hh-home-our-ser-para-div">
                      <p className="hh-home-our-ser-para">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse varius enim
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Latest Jobs Section --> */}

          <div className="career">
            {/* <div className="container"> */}
            <div className="c-left">
              <div className="content2">
                <p className="hh-home-late-jobs-tag">Tagline</p>
                <div className="content22">
                  <h2 className="hh-home-late-jobs-title">
                    {t("translation.latestJobs")}
                  </h2>
                  <p className="hh-home-late-jobs-para">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim
                  </p>
                </div>
              </div>
              <img src={latestjobwomen} alt="women_pic" className="lady-img" />
            </div>
            <div id="latest-jobs" className="c-right">
              {jobs && jobs.length > 0 ? (
                <>
                  {jobs.slice(0, 3).map((job) => (
                    <JobTitleCard
                      key={job.custReqId}
                      title={job.position}
                      expectations={
                        job?.expectations !== null
                          ? parse(job?.expectations)
                          : ""
                      }
                      location={`${job.city}, ${job.state}, ${job.country}`}
                    />
                  ))}
                </>
              ) : (
                <>
                  <div className="hh-home-lat-join-when-no-job-div">
                    <div className="no-jobs-found-img-div">
                      <img
                        src={NoJobsFoundImage}
                        alt="no-job-found"
                        className="no-job-found-img"
                      />
                    </div>
                    <div className="hh-home-no-job-found-text-div">
                      <p className="hh-home-no-job-found-text">No jobs available at the moment.</p>
                      <p className="hh-home-no-job-found-text"> Please visit again later.</p>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* </div> */}
          </div>
          {/* <!-- why-choose Section --> */}
          <div className="choose-us background-gradient-color">
            <div className="title">
              <h2 className="hh-home-wcu-title">
                {t("translation.whychooseus")}
              </h2>
              <p className="hh-home-wcu-para">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
              </p>
            </div>
            <div className="row">
              <div className="column">
                <img src={Handshake} alt="" className="" />
                <div className="col1">
                  <h5 className="hh-home-ch-us-sec-title">
                    {t("translation.Reliable")}
                  </h5>
                  <p className="hh-home-ch-us-sec-para">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </p>
                </div>
              </div>
              <div className="column">
                <img src={Onlineshop} alt="" className="" />
                <div className="col1">
                  <h5 className="hh-home-ch-us-sec-title">
                    {t("translation.TransparentPricing")}
                  </h5>
                  <p className="hh-home-ch-us-sec-para">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </p>
                </div>
              </div>
              <div className="column">
                <img src={Support} alt="" className="" />
                <div className="col1">
                  <h5 className="hh-home-ch-us-sec-title">
                    {t("translation.CustomerSupport")}
                  </h5>
                  <p className="hh-home-ch-us-sec-para">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* about us -- */}
          <div className="about">
            <div className="about-1">
              <p className="hh-home-about-tag">
                {" "}
                {t("translation.Featureone")}
              </p>
              <div className="about-content">
                <h2 className="hh-home-about-title">
                  {t("translation.AboutHeartyHelper")}
                </h2>
                <p className="hh-home-about-para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  varius faucibus massa sollicitudin amet augue. Nibh metus a
                  semper purus mauris duis. Lorem eu neque, tristique quis duis.
                  Nibh scelerisque ac adipiscing velit non nulla in amet
                  pellentesque. Sit turpis pretium eget maecenas. Vestibulum
                  dolor mattis consectetur eget commodo vitae.
                </p>
                <p className="hh-home-about-para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  varius faucibus massa sollicitudin amet augue. Nibh metus a
                  semper purus mauris duis. Lorem eu neque, tristique quis duis.
                  Nibh scelerisque ac adipiscing velit non nulla in amet
                  pellentesque. Sit turpis pretium eget maecenas. Vestibulum
                  dolor mattis consectetur eget commodo vitae.
                </p>
              </div>
            </div>
            <img src={wood} alt="Connect People" className="wood-img" />
            <img
              src={mobilewood}
              alt="Connect People"
              className="wood-img-mobile"
            />
          </div>

          {/* <!-- Latest Joining Section --> */}

          <div className="joining background-gradient-color">
            <div className="joining-head">
              <h2 className="hh-home-lat-joining-title">
                {t("translation.LatestJoining")}
              </h2>
              <p className="hh-home-lat-joining-para">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "}
              </p>
            </div>
            <div className="all-blocks-crd">
              <Swiper
                loop={true} // Add this line to enable continuous looping
                slidesPerView={4}
                spaceBetween={400}
                autoplay={{ delay: 700 }}
                pagination={{ clickable: true }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
              >
                {latestJoinee?.map((latestJoinees) => (
                  <SwiperSlide
                    key={latestJoinees.empId}
                    className="swiper-slide"
                  >
                    <LatestJoiningCard
                      name={latestJoinees.name}
                      profileImageUrl={
                        latestJoinees.profileImageUrl || usercomment
                      }
                      role={latestJoinees.role ?? "Role N/A"}
                      bio={
                        latestJoinees?.bio !== null
                          ? parse(latestJoinees?.bio)
                          : "Bio N/A"
                      }
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div className="testimonial">
            <div className="t-head">
              <h2 className="hh-home-cu-testi-heading">
                {t("translation.Customertestimonials")}
              </h2>
              <p className="hh-home-cu-testi-tagline">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
            <div className="boxes-with-dots">
              <div className="boxes">
                {/* <!-- Box of One Customer --> */}
                <Swiper
                  loop={true} // Add this line to enable continuous looping
                  slidesPerView={2}
                  spaceBetween={30}
                  autoplay={{ delay: 700 }}
                  pagination={{ clickable: true }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper"
                >
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <TestimonalCard />
                  </SwiperSlide>
                </Swiper>
                {/* <TestimonalCard /> */}
                {/* <TestimonalCard /> */}
                {/* <TestimonalCard /> */}
              </div>{" "}
            </div>
          </div>
        </div>
      </StaticHomePageLayout>
    </>
  );
};
export default HomePage;
